import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { Layout } from '../components/Layout/Layout'
import { Intro } from '../modules/Intro/Intro';

const NotFoundPage = (): ReactElement => (
  <Layout>

    <Intro
      title={
        <> 404: Page not found. </>
      }
      subTitle={<>You've hit the void. <Link to="/">Go back.</Link></>}
      
    />
  </Layout>
)

export default NotFoundPage
